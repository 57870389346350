.App {
  background-image: url("./images/clouds.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-header {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

table {
  margin-top: 20px;
  border-collapse: collapse;
  max-width: 800px;
}

th,
td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ccc;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin-bottom: 6px;
  font-size: 14px;
}

.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  border-radius: 15px;
}

@media screen and (max-width: 500px) {
  .App-header {
    width: 85%;
  }

  .scroll {
    overflow-x: auto;
    width: 100%;
  }
}
